<template>
  <div>
    <dashboard-page-title :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">الحجوزات</dashboard-page-title>
    <main-table :fields="fields" list_url="admin/reservations" :baseURL="VUE_APP_VETE_LINK"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'

export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'اسم العميل', key: 'customer_name', class: 'text-right' },
        { label: 'اسم الطبيب', key: 'doctor.full_name', class: 'text-right' },
        { label: 'المكان', key: 'doctor.clinic_address', class: 'text-right' },
        { label: 'تكلفة الكشف', key: 'price', class: 'text-right' },
        { label: 'تاريخ الزيارة', key: 'time.date', class: 'text-right' },
        { label: 'تاريخ الحجز', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
